<template>
  <calc-page>
    <template slot="topBox">
      <round-container>
        <number-input-field
          name="基本养老金"
          v-model="params.baseAnnuity"
        ></number-input-field>
        <number-input-field
          name="个人账户养老金"
          v-model="params.personAnnuity"
        ></number-input-field>
      </round-container>

      <round-container>
        <year-month-input-field
          name="退休时间"
          v-model="params.retireDate"
        ></year-month-input-field>
        <year-month-input-field
          name="死亡时间"
          v-model="params.dieDate"
        ></year-month-input-field>
        <number-input-field
          name="退休时年龄"
          v-model="params.retireAge"
          unit="岁"
          :limit="2"
        ></number-input-field>
      </round-container>
    </template>

    <template slot="footerBtn">
      <div
        v-bind:class="['btn-box', 'btn-bk']"
        @click="onSubmit"
      >
        开始计算
      </div>
    </template>
  </calc-page>
</template>

<script>
import CalcPage from "@/components/CalcPage";
import RoundContainer from "@/views/calc/components/RoundContainer";
import NumberInputField from "@/views/calc/components/NumberInputField";
import YearMonthInputField from "@/views/calc/components/YearMonthInputField";
import to from "@/utils/to";
import { calcAnnuityReturnRetireDie } from "@/api/tool";
import { Dialog } from 'vant';

export default {
  components: {
    CalcPage,
    RoundContainer,
    NumberInputField,
    YearMonthInputField,
  },
  computed: {
    btnDisabled() {
      const {
        baseAnnuity,
        personAnnuity,
        retireDate,
        retireAge,
        dieDate,
      } = this.params;

console.log(this.params)
      if (
        baseAnnuity === "" ||
        personAnnuity === "" ||
        retireDate === "" ||
        retireAge == "" ||
        dieDate === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      params: {
        baseAnnuity: "",
        personAnnuity: "",
        retireDate: "",
        retireAge: "",
        dieDate: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      const itemRetire = new Date(this.params.retireDate);
      const itemDie = new Date(this.params.dieDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      if (itemRetire > new Date()) {
        Dialog.alert({
          title: "退休时间不正确",
          message: "退休时间不能大于当前年月：" + currentYear + "/" + currentMonth +"，请重新输入"
        });
        return;
      }

      if (itemRetire > itemDie) {
        Dialog.alert({
          title: "死亡日期不正确",
          message: "死亡日期不能小于退休日期"
        });
        return;
      }

      if (this.btnDisabled) {
        this.$toast.fail("请先填写相关信息");
        return;
      }

      this.$showLoading("加载中...");
      const data = {
        baseAnnuity: this.params.baseAnnuity,
        personAnnuity: this.params.personAnnuity,
        retireDate: this.params.retireDate + '/01',
        dieDate: this.params.dieDate + '/01',
        retireAge: this.params.retireAge
      }
      
      let [err, res] = await to(calcAnnuityReturnRetireDie(data));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }
      this.$router.push({
        name: "AnnuityReturnRetireDieResPage",
        query: { res: JSON.stringify(res) },
      });
    },
  },
};
</script>

<style scoped>
.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}
</style>